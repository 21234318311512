import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import styled from "styled-components";
import { useUpdateFrequency } from "../../hooks/NextDeliveries";
import { TFrequenceEnum } from "../../types/PickupPoint";
import { TSubscriptionComplete } from "../../types/Subscription";
import { FormTagRadio } from "../FormTagRadio";
import { SelectOption } from "../FormTagRadio/FormTagRadio";
import { Label } from "../Label";
import { theme } from "../theme";
import { AddressForm } from "./AddressForm";
import { MapSection } from "./MapSection";
import {
  useCreateLADDelivery,
  useTimeslotsByZipcode,
} from "../../hooks/Delivery";
import { useUser } from "../../hooks/Users";
import { Button } from "../Button";
import { useHistory } from "react-router-dom";
import { TimeSlotSelector } from "./AddressForm/AddressForm";
import { TTimeSlot } from "../../types/TimeSlot";

interface Props {
  isSignup: boolean;
  subscription?: TSubscriptionComplete;
  onDone: () => void;
}

const DeliveryModes = {
  COLLECT: "PUP",
  HOUSE: "LAD",
};

export const Delivery: React.FC<Props> = ({
  isSignup,
  subscription,
  onDone,
}) => {
  const { t } = useTranslation();
  const { mutate: mutateFrequency } = useMutation(useUpdateFrequency);
  const [deliveryMode, setDeliveryMode] = useState<string>(
    subscription?.delivery?.entity_type || DeliveryModes.HOUSE
  );
  const [frequency, setFrequency] = useState<number>(7);

  const deliveryOptions = [
    {
      label: `${t(`Signup.step3.delivery.collect`)}`,
      complement: `${t(`Signup.step3.delivery.collect.price`)}`,
      value: DeliveryModes.COLLECT,
    },
    {
      label: `${t(`Signup.step3.delivery.house`)}`,
      complement: `${t(`Signup.step3.delivery.house.price`)}`,
      value: DeliveryModes.HOUSE,
    },
  ] as SelectOption[];

  const frequencyOptions = [
    {
      label: t("Plan.frequency.7"),
      value: 7,
    },
    {
      label: t("Plan.frequency.14"),
      value: 14,
    },
  ] as SelectOption[];

  useEffect(() => {
    if (subscription && subscription.delivery) {
      setDeliveryMode(subscription.delivery.entity_type);
      setFrequency(subscription.frequence);
    }
  }, [subscription]);

  const onCancelChanges = () => {
    if (subscription && subscription.delivery) {
      setFrequency(subscription.frequence);
      setDeliveryMode(subscription.delivery.entity_type);
    }
  };

  const onFrequencyChange = (value: number) => {
    setFrequency(value);
  };

  const onHandleDone = () => {
    if (!isSignup) {
      mutateFrequency({ frequency: frequency as TFrequenceEnum });
    }
    onDone();
  };
  return (
    <SectionStyled>
      <RadioSectionStyled>
        <LabelStyled size="mdx">{t("Signup.step3.delivery.label")}</LabelStyled>

        <FormTagRadio
          id="entity_type"
          name="entity_type"
          error
          type={"string"}
          value={deliveryMode}
          options={deliveryOptions}
          onChange={(value) => setDeliveryMode(value as string)}
          register={null}
          validation
        />
      </RadioSectionStyled>
      {/* <SpanStyled>{t("Cart.freeShipping")}</SpanStyled> */}

      <RadioSectionStyled>
        <LabelStyled size="mdx">
          {t("Signup.step3.frequency.label")}
        </LabelStyled>
        <FormTagRadio
          id="entity_type"
          name="entity_type"
          error
          type={"number"}
          value={frequency}
          options={frequencyOptions}
          onChange={(value) => onFrequencyChange(value as number)}
          register={null}
          validation
        />
      </RadioSectionStyled>
      <>
        {deliveryMode === DeliveryModes.COLLECT && (
          <MapSection
            isSignup={isSignup}
            subscription={subscription}
            frequency={frequency as TFrequenceEnum}
            showLocations={true}
            showForm={true}
            onCancelChanges={onCancelChanges}
            onDone={onHandleDone}
          />
        )}
        {deliveryMode === DeliveryModes.HOUSE && (
          <AddressSection
            isSignup={isSignup}
            subscription={subscription}
            frequency={frequency as TFrequenceEnum}
            onCancelChanges={onCancelChanges}
            onDone={onHandleDone}
          />
        )}
      </>
    </SectionStyled>
  );
};

const AddressSection = ({
  isSignup,
  subscription,
  frequency,
  onCancelChanges,
  onDone,
}: {
  isSignup: boolean;
  subscription: TSubscriptionComplete | undefined;
  frequency: number;
  onCancelChanges: () => void;
  onDone: () => void;
}) => {
  const { data: user } = useUser();
  const { t } = useTranslation();
  const history = useHistory();
  const { mutate, isSuccess, isLoading } = useMutation(useCreateLADDelivery);
  const [modify, setModify] = useState(false);
  const [timeslots, setTimeslots] = useState<TTimeSlot[]>([]);
  const [timeslotSelected, setTimeslotSelected] = useState<string>();

  const { data: timeslotsFound } = useTimeslotsByZipcode(
    user?.zip_code || "",
    user?.province
  );

  useEffect(() => {
    if (subscription && subscription.delivery && timeslotsFound) {
      setTimeslotSelected(subscription.delivery.timeslot_id.toString());
    }
  }, [subscription, timeslotsFound]);

  useEffect(() => {
    if (timeslotsFound) {
      setTimeslots(timeslotsFound);
    }
  }, [timeslotsFound]);

  const onSlotSubmitHandler = (timeslotId: string) => {
    setTimeslotSelected(timeslotId);
  };

  const onFormSubmitHandler = () => {
    if (!user) return;
    const address = {
      address: user.address,
      address2: user.address2,
      city: user.city,
      province: user.province,
      zip_code: user.zip_code,
      country: user.country,
      phone: user.phone,
      note: user.note,
    };
    if (timeslotSelected && frequency) {
      mutate({
        timeslotId: timeslotSelected,
        frequence: frequency,
        address: { ...address },
      });
    }
  };

  useEffect(() => {
    isSuccess && onDone();
  }, [isSuccess]);

  const redirectTo = (path: string) => () => history.push(path);

  if (modify)
    return (
      <>
        <Button
          onClick={() => setModify(false)}
          variant="secondary"
          size="md"
          disabled={isLoading}
        >
          Annuler
        </Button>
        <AddressForm
          isSignup={isSignup}
          subscription={subscription}
          frequency={frequency as TFrequenceEnum}
          onCancelChanges={onCancelChanges}
          onDone={onDone}
        />
      </>
    );

  return (
    <>
      <RadioSectionStyled>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            gap: "2rem",
          }}
        >
          {timeslotsFound?.length === 0 && (
            <div>
              <Button size="sm" to="/waiting-list">{t(`Home.map.cta2`)}</Button>
            </div>
          )}
          <AddressSectionStyled>
            <YourAddress>
              <LabelStyled size="mdx">
                {t("Signup.step3.your-address")}
              </LabelStyled>
              <AddressLabelStyled>
                {user?.address} {user?.address2}, {user?.city}
              </AddressLabelStyled>
            </YourAddress>
            <Button
              onClick={() => setModify(true)}
              secondary
              size="md"
              disabled={isLoading}
            >
              {t("Signup.step3.edit-address")}
            </Button>
          </AddressSectionStyled>
        </div>
      </RadioSectionStyled>

      <RadioSectionStyled>
        {timeslotsFound && (
          <TimeSlotSelector
            timeslotSelected={timeslotSelected}
            timeslots={timeslots}
            onSelect={onSlotSubmitHandler}
          />
        )}
      </RadioSectionStyled>

      <BottomSectionStyled>
        <Button primary size="lg" onClick={redirectTo("/sign-up/step-2")}>
          {t("Common.action.back")}
        </Button>
        <Button
          secondary
          size="lg"
          disabled={
            !frequency ||
            (user?.address &&
            timeslotSelected &&
            timeslotsFound &&
            timeslotsFound?.length > 0
              ? false
              : true)
          }
          onClick={() => onFormSubmitHandler()}
        >
          {isSignup ? t("Signup.next") : t("Common.action.save")}
        </Button>
      </BottomSectionStyled>
    </>
  );
};

const YourAddress = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  @media (max-width: ${theme.breakpoints.sm}) {
    justify-content: space-between;
    width: 100%;
  }
`;

const AddressSectionStyled = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  @media (max-width: ${theme.breakpoints.sm}) {
    flex-direction: column;
    gap: ${theme.spacing[2]};
  }
`;

const SectionStyled = styled.div`
  display: flex;
  flex-direction: column;
  > div {
    margin-top: 2px;
  }
`;

const RadioSectionStyled = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${theme.spacing[1.5]} ${theme.spacing[1]};
  background-color: ${theme.colors.accent.secondary};

  @media (min-width: ${theme.breakpoints.sm}) {
    flex-direction: row;
    align-items: center;
    padding: ${theme.spacing[3]} ${theme.spacing[2]};
  }
`;

const AddressLabelStyled = styled(Label)`
  width: fit-content;
  font-size: ${theme.font.size.lg};
  font-family: ${theme.font.family.secondary};
  font-weight: ${theme.font.weight.bold};
  line-height: ${theme.font.size.lg};
  color: ${theme.colors.base.dark};
  text-align: right;
  @media (max-width: ${theme.breakpoints.sm}) {
    font-size: ${theme.font.size.md};
  }
`;

const LabelStyled = styled(Label)`
  width: 170px;
  margin-right: ${theme.spacing[2]};
  font-family: ${theme.font.family.secondary};
  font-weight: ${theme.font.weight.bold};
  line-height: ${theme.font.size.lg};
  color: ${theme.colors.base.secondary};
`;

const BottomSectionStyled = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: ${theme.spacing[1]};

  > button {
    width: 200px;
    margin-left: ${theme.spacing[0.5]};
    margin-right: ${theme.spacing[0.5]};

    &:first-of-type {
      margin-left: 0;
    }

    &:last-of-type {
      margin-right: 0;
    }
  }

  @media (max-width: ${theme.breakpoints.sm}) {
    margin-right: ${theme.spacing[1]};
  }
`;
