import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import { useAppState } from "../../contexts";
import { useCategoriesTree } from "../../hooks/Categories";
import { SpecialCategories, TCategory } from "../../types/Category";
import usePrevious from "../../utils/hooks";
import { Label } from "../Label";
import { SearchAlgolia } from "../SearchAlgolia";
import { spacing, theme } from "../theme";
import { CategoryMemorized as Category } from "../Filter/Category";

const FrontElement = {
  TREE: "tree",
  SEARCH: "search",
};

interface Props {
  selectedCategory?: string;
  searchQuery: string;
  showSearch: boolean;
  isConnectedUser: boolean;
}

export const SideFilter: React.FC<Props> = ({
  selectedCategory,
  searchQuery,
  showSearch,
  isConnectedUser,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { locale } = useAppState();
  const { search } = useLocation();
  const [lang] = locale.split("-");
  const { data: tree = [] } = useCategoriesTree();
  const [frontElement, setFrontElement] = useState(FrontElement.TREE);
  const [flatTree, setFlatTree] = useState<TCategory[]>();
  const previousTree = usePrevious(tree);

  useEffect(() => {
    if (JSON.stringify(tree) !== JSON.stringify(previousTree)) {
      const flattenTree = tree
        .map((cat) => {
          const { subcategories, ...catProps } = cat;
          return [...(subcategories ? subcategories : []), catProps];
        })
        .reduce((acc, val) => [...acc, ...val], []);
      setFlatTree(flattenTree);
    }
  }, [tree]);

  function updateTreeLocalStorage() {
    const isTree = localStorage.getItem("tree");
    const booleanValue = Boolean(isTree === "true");
    const invertedValue = !booleanValue;
    localStorage.setItem("tree", invertedValue ? "true" : "");
  }

  const onClickSearch = () => {
    frontElement === FrontElement.TREE && setFrontElement(FrontElement.SEARCH);
  };

  const onSelectCategory = (category: TCategory) => {
    if (!isConnectedUser) {
      return history.push({ pathname: "/dashboard" });
    }
    const params = new URLSearchParams(search);
    const currentOrder = new URLSearchParams().get("order_by");
    if (currentOrder) params.set("order_by", currentOrder);
    updateTreeLocalStorage();
    params.set("category", category.cid);
    params.delete("search");
    history.push({ pathname: "/products", search: params.toString() });
  };

  const onSelectSpecialCategory = (specialCategory: string) => {
    if (!isConnectedUser) {
      return history.push({ pathname: "/dashboard" });
    }
    const params = new URLSearchParams(search);
    const currentOrder = new URLSearchParams().get("order_by");
    if (currentOrder) params.set("order_by", currentOrder);
    console.log("current order ", currentOrder);
    updateTreeLocalStorage();
    params.set("category", specialCategory);
    params.delete("search");
    history.push({ pathname: "/products", search: params.toString() });
  };

  const firstColumCategories = tree.slice(0, Math.min(2, tree.length));
  const treeColumns = tree.length > 2 ? tree.slice(2) : [];

  let title = "";
  switch (selectedCategory) {
    case SpecialCategories.PROMO:
      title = t("Catalog.promos");
      break;
    case SpecialCategories.LOCALS:
      title = t("Catalog.locals");
      break;
    case SpecialCategories.SAVED:
      title = t("Catalog.saved");
      break;
    case SpecialCategories.NEW:
      title = t("Catalog.new");
      break;
    default:
      const categoryTitle = flatTree?.find(
        (cat) => cat.cid === selectedCategory
      )?.copy[lang]?.name;
      if (categoryTitle) {
        title = categoryTitle;
      }
      break;
  }
  if (searchQuery) {
    title = `${t("Catalog.search.results")} ${searchQuery}`;
  }
  return (
    <TreeWrapper>
      <TopSectionWrapper>
        {showSearch && isConnectedUser && (
          <SearchAlgolia
            onSendRequest={(done) => {
              console.log(done);
            }}
            onClick={onClickSearch}
            showFull={frontElement === FrontElement.SEARCH}
          />
        )}
        <FirstColumnWrapper>
          <SpecialCategoriesWrapper>
            <SpecialCategory
              onClick={() => onSelectSpecialCategory(SpecialCategories.PROMO)}
            >
              <PromoTag
                bgColor={theme.colors.base.primary}
                hoverColor={theme.colors.base.primary}
                selected={selectedCategory === "is_promo"}
                key="local"
              >
                {t("Catalog.promos")}
              </PromoTag>
            </SpecialCategory>
            <SpecialCategory
              onClick={() => onSelectSpecialCategory(SpecialCategories.LOCALS)}
            >
              <PromoTag
                bgColor={"#003da5"}
                hoverColor={"#003da5"}
                selected={selectedCategory === "is_local"}
                key="local"
              >
                {t("Catalog.locals")}
              </PromoTag>
            </SpecialCategory>
            <SpecialCategory
              onClick={() => onSelectSpecialCategory(SpecialCategories.SAVED)}
            >
              <PromoTag
                bgColor={theme.colors.base.secondary}
                hoverColor={theme.colors.base.secondary}
                selected={selectedCategory === "is_saved"}
                key="saved"
              >
                {t("Catalog.saved")}
              </PromoTag>
            </SpecialCategory>
            <SpecialCategory
              onClick={() => onSelectSpecialCategory(SpecialCategories.NEW)}
            >
              <PromoTag
                bgColor={theme.colors.base.third}
                hoverColor={theme.colors.base.third}
                selected={selectedCategory === "is_featured"}
                key="new"
              >
                {t("Catalog.new")}
              </PromoTag>
            </SpecialCategory>
          </SpecialCategoriesWrapper>
        </FirstColumnWrapper>
      </TopSectionWrapper>

      <ColumnWrapper>
        <FilterTitle size="xl" weight="black">
          Nos allées
        </FilterTitle>
        <Separator />
        {firstColumCategories && firstColumCategories.length > 0 && (
          <>
            {firstColumCategories.map((cat) => (
              <Category
                key={cat.cid}
                category={cat}
                subcategories={cat.subcategories}
                onSelectCategory={onSelectCategory}
              />
            ))}
          </>
        )}
        {treeColumns && treeColumns.length > 0 && (
          <>
            {treeColumns.map((element) => {
              return (
                <Category
                  key={element.cid}
                  category={element}
                  subcategories={element.subcategories}
                  onSelectCategory={onSelectCategory}
                />
              );
            })}
          </>
        )}
      </ColumnWrapper>
    </TreeWrapper>
  );
};

const PromoTag = styled.span<{
  bgColor: string;
  hoverColor: string;
  selected: boolean;
}>`
  align-self: flex-start;
  margin: 2px;
  margin-left: 0;
  padding: 8px 16px;
  white-space: nowrap;
  border-radius: 64px;
  border: 1px solid ${(props) => props.bgColor};
  font-family: ${theme.font.family.secondary};
  font-size: ${theme.font.size.md};
  line-height: ${theme.font.size.md};
  width: fit-content;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;

  ${(props) =>
    props.selected
      ? `background: transparent;
    color: ${props.hoverColor};
    `
      : `background: ${props.bgColor};
     color: ${theme.colors.base.white};
     `};

  :hover {
    color: ${(props) => props.hoverColor};
  }
`;

const FilterTitle = styled(Label)`
  flex: 1;
  font-family: ${theme.font.family.secondary};
  line-height: ${theme.font.size.xxl};
  color: ${theme.colors.base.secondary};
`;

const TreeWrapper = styled.div`
  position: sticky;
  height: 100vh;
  top: 0;
  display: none;
  flex-direction: column;
  background-color: white;
  padding: 1rem 2rem 1rem 2rem;
  gap: 1rem;
  overflow-y: scroll;

  @media (min-width: ${theme.breakpoints.md}) {
    display: flex;
  }
`;

const TopSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const ColumnWrapper = styled.div`
  height: auto;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
`;

const FirstColumnWrapper = styled(ColumnWrapper)`
  flex-direction: column-reverse;

  @media (min-width: ${theme.breakpoints.sm}) {
    flex-direction: column;
  }
`;

const SpecialCategoriesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: ${theme.spacing[1]};
  column-gap: 0.25rem;
  max-width: 25rem;

  @media (min-width: ${theme.breakpoints.sm}) {
    margin-bottom: 0;
  }
`;

const SpecialCategory = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  padding: 5px 0;

  span:hover {
    background: none;
  }
`;

const Separator = styled.div`
  width: 100%;
  height: 1px;
  margin-bottom: 1rem;
  background: ${theme.colors.accent.secondary};
`;
