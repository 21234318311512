import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import styled from "styled-components";
import {
  useAppState,
  useDeliveryDispatch,
  useDeliveryState,
} from "../../../contexts";
import { getByDistance } from "../../../contexts/Delivery/DeliveryService";
import { useCreatePUPDelivery } from "../../../hooks/Delivery";
import { useUser } from "../../../hooks/Users";
import { TGeolocation } from "../../../types/Geolocation";
import { TFrequenceEnum, TPickupLocation } from "../../../types/PickupPoint";
import { TSubscriptionComplete } from "../../../types/Subscription";
import { formatTimeslotDay, formatTimeslotHours } from "../../../utils/Date";
import { Button } from "../../Button";
import { TChoice } from "../../ChoiceList/ChoiceList";
import { Label } from "../../Label";
import { Loading } from "../../Loading";
import { LocationList } from "../../LocationList";
import { Map } from "../../Map";
import { ModalPassword } from "../../Map/ModalPassword";
import { Snackbar } from "../../Snackbar";
import { theme } from "../../theme";
import { AddressAutocomplete } from "../AddressForm/AddressAutocomplete";

type TMapState = {
  current: TPickupLocation | null;
  center: TGeolocation | null;
  zoom: number;
};

interface Props {
  isSignup: boolean;
  subscription?: TSubscriptionComplete;
  frequency: TFrequenceEnum;
  showLocations: boolean;
  showForm: boolean;
  onCancelChanges?: () => void;
  onDone: () => void;
  viewOnly?: boolean;
}

const defaultZoom = 10;
const defaultGeolocation = {
  lat: 45.518079,
  lng: -73.565183,
};

export const MapSection: React.FC<Props> = ({
  isSignup,
  subscription,
  frequency,
  showLocations,
  showForm,
  onCancelChanges,
  onDone,
  viewOnly,
}) => {
  const { t } = useTranslation();
  const { locale } = useAppState();
  const { data: user } = useUser();
  const deliveryDispatch = useDeliveryDispatch();
  const { locations } = useDeliveryState();

  const { mutate, isSuccess, isLoading } = useMutation(useCreatePUPDelivery);

  const [currentPosition, setCurrentPosition] = useState<TGeolocation>();
  const [locationSelected, setLocationSelected] = useState<TPickupLocation>();
  const [availabilitySelected, setAvailabilitySelected] = useState<TChoice>();
  const [isOpen, setIsOpen] = useState<boolean>();
  const [isPassword, setIsPassword] = useState<string>();
  const [wrongPassword, setWrongPassword] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [incompatibleFrequency, setIncompatibleFrequency] =
    useState<boolean>(false);
  const [map, setMap] = useState<TMapState>({
    current: null,
    center: null,
    zoom: defaultZoom,
  });

  useEffect(() => {
    isSuccess && onDone();
  }, [isSuccess]);

  useEffect(() => {
    if (
      subscription &&
      subscription.delivery &&
      subscription.delivery.entity_type === "PUP"
    ) {
      geoLocations({
        lat: subscription.delivery.lat,
        lng: subscription.delivery.lng,
      });
    } else {
      geoLocations(defaultGeolocation);
    }
  }, [subscription]);

  useEffect(() => {
    if (!locationSelected) {
      resetLocation();
    }
  }, [subscription, locations]);

  useEffect(() => {
    if (locationSelected) {
      setIncompatibleFrequency(locationSelected.frequence > frequency);
    }
  }, [frequency]);

  useEffect(() => {
    if (!subscription) {
      geoLocations(defaultGeolocation);
    }
  }, []);

  const resetLocation = () => {
    if (
      subscription &&
      subscription.delivery &&
      subscription.delivery.entity_type === "PUP" &&
      locations
    ) {
      const pupSelected = locations.find(
        (loc) => loc.id === subscription.delivery.entity_id
      );
      if (pupSelected) {
        setLocationSelected(pupSelected);
        setIsOpen(pupSelected.open);
        const timeslot = pupSelected?.timeslots?.find(
          (slot) => slot.id === subscription.delivery.timeslot_id
        );
        timeslot &&
          setAvailabilitySelected({
            day: `${formatTimeslotDay(timeslot?.day, locale, "short")}`,
            time: `${formatTimeslotHours(
              timeslot?.day,
              timeslot?.start_time,
              timeslot?.end_time,
              locale,
              "short"
            )}`,
            value: `${timeslot.id}`,
          });
      }
    }
  };

  const geoLocations = useCallback(
    async (geolocation: TGeolocation) => {
      const lat = geolocation?.lat || defaultGeolocation.lat;
      const lng = geolocation?.lng || defaultGeolocation.lng;
      getByDistance(lat, lng, 500).then((response) => {
        deliveryDispatch({
          type: "SET_LOCATIONS",
          payload: {
            locations: response.payload,
          },
        });

        setMap({
          ...map,
          zoom: 13,
          center: {
            ...(geolocation || defaultGeolocation),
          },
        });

        setCurrentPosition(geolocation);
      });
    },
    [map, setCurrentPosition, deliveryDispatch]
  );

  const geoLocationsClicked = useCallback(
    async (geolocation: TGeolocation) => {
      const lat = geolocation?.lat || defaultGeolocation.lat;
      const lng = geolocation?.lng || defaultGeolocation.lng;
      getByDistance(lat, lng, 500).then((response) => {
        deliveryDispatch({
          type: "SET_LOCATIONS",
          payload: {
            locations: response.payload,
          },
        });
      });
    },
    [map, setCurrentPosition, deliveryDispatch]
  );

  const onLocationSelectHandler = (
    index: number,
    open: boolean,
    password: string
  ): void => {
    setIncompatibleFrequency(false);
    setLocationSelected(locations[index]);
    if (locations[index].timeslots?.length === 1) {
      const timeslot = locations[index].timeslots![0];
      setAvailabilitySelected({
        day: `${formatTimeslotDay(timeslot?.day, locale, "short")}`,
        time: `${formatTimeslotHours(
          timeslot?.day,
          timeslot?.start_time,
          timeslot?.end_time,
          locale,
          "short"
        )}`,
        value: `${timeslot.id}`,
      });
    }
    setIsOpen(open);
    setIsPassword(password);
    setShowModal(true);
    setIncompatibleFrequency(open && locations[index].frequence > frequency);
  };

  const onTimeSelectHandler = (availability: TChoice): void => {
    setAvailabilitySelected(availability);
  };

  const onCancelHandler = () => {
    resetLocation();
    onCancelChanges && onCancelChanges();
  };

  const onSubmitHandler = () => {
    if (!user || !locationSelected || !availabilitySelected) {
      return;
    }
    mutate({
      ppid: locationSelected.ppid,
      timeslotId: availabilitySelected.value,
      frequence: frequency,
    });
  };

  const handleUserPassword = useCallback(
    (pass: string) => {
      setWrongPassword(false);
      if (!user || !locationSelected || !availabilitySelected || !frequency) {
        return;
      }

      if (pass !== isPassword) {
        setWrongPassword(true);
        setLocationSelected(undefined);
        setIsOpen(false);
        setIsPassword(undefined);
        setShowModal(false);
        setAvailabilitySelected(undefined);
        return;
      }
      setShowModal(false);
      setIsPassword(undefined);
      return;
    },
    [user, locationSelected, availabilitySelected]
  );

  return (
    <>
      <MapWrapper>
        {showForm && (
          <AdressWrapper>
            <LabelStyled weight="bold">
              {t("MapSection.search.zipCode")}
            </LabelStyled>
            <AddressAutocomplete
              setLatLng={(latLng) => {
                geoLocations(latLng);
              }}
              setValue={() => {}}
              locale="fr-CA"
              region="ca"
            />
          </AdressWrapper>
        )}
        <MapStyled>
          {map.center ? (
            <Map
              markers={showLocations ? locations : []}
              currentPosition={currentPosition}
              current={map.current}
              center={map.center}
              zoom={map.zoom}
              draggable={true}
              key={map.center.lat}
              onSelectedPup={(pup) => {
                if (pup?.lat && pup?.lng) {
                  geoLocationsClicked({ lat: pup?.lat, lng: pup?.lng });
                }
              }}
            />
          ) : null}
        </MapStyled>

        {showLocations && (
          <>
            <LocationListWrapper>
              <LocationList
                id="pickup"
                viewOnly={viewOnly}
                locationSelected={locationSelected}
                pickupPoints={locations?.slice(0, 10)}
                timeSelected={availabilitySelected}
                onLocationSelect={onLocationSelectHandler}
                onTimeSelect={onTimeSelectHandler}
              />
            </LocationListWrapper>
            {isPassword && availabilitySelected && (
              <ModalPassword
                visible={showModal}
                onClose={() => {
                  setLocationSelected(undefined);
                  setIsOpen(false);
                  setIsPassword(undefined);
                  setShowModal(false);
                  setAvailabilitySelected(undefined);
                  setWrongPassword(false);
                }}
                onUserInput={handleUserPassword}
              />
            )}
            {wrongPassword && (
              <Snackbar
                type={"error"}
                text={t("Signup.step3.wrong-password")}
              />
            )}
            {incompatibleFrequency && (
              <Snackbar
                type={"error"}
                text={t("Signup.step3.frequency.incompatible")}
                onDismiss={() => setIncompatibleFrequency(false)}
              />
            )}
          </>
        )}
      </MapWrapper>
      {!viewOnly && (
        <BottomSectionStyled>
          {!isSignup && (
            <Button secondary onClick={onCancelHandler}>
              {t("Common.action.cancel")}
            </Button>
          )}
          <Button
            secondary
            size="lg"
            onClick={onSubmitHandler}
            disabled={
              !frequency ||
              !availabilitySelected ||
              !locationSelected ||
              !isOpen ||
              (isPassword && !wrongPassword) ||
              locationSelected.frequence > frequency
            }
          >
            {isSignup ? t("Signup.next") : t("Common.action.save")}
          </Button>
        </BottomSectionStyled>
      )}
      {isLoading && (
        <LoadingWrapper>
          <Loading />
        </LoadingWrapper>
      )}
      {isSuccess && (
        <Snackbar type={"success"} text={t("Snackbar.changed.delivery")} />
      )}
    </>
  );
};

const MapStyled = styled.div`
  position: relative;
  height: 420px;
  width: 100%;
`;

const AdressWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: ${theme.spacing[1]};
`;

const LabelStyled = styled(Label)`
  margin-top: ${theme.spacing[1]};
  margin-bottom: ${theme.spacing[0.5]};
  font-family: ${theme.font.family.secondary};
  line-height: ${theme.font.size.lg};
  color: ${theme.colors.base.secondary};

  @media (min-width: ${theme.breakpoints.sm}) {
    font-size: ${theme.font.size.xl};
    line-height: ${theme.font.size.xxl};
  }
`;

const LocationListWrapper = styled.div`
  margin-top: ${theme.spacing[1]};
`;

const MapWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: ${theme.spacing[1]};
  background-color: ${theme.colors.accent.secondary};
`;

const BottomSectionStyled = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-top: ${theme.spacing[2]};

  > button {
    width: 200px;
    margin-left: ${theme.spacing[0.5]};
    margin-right: ${theme.spacing[0.5]};

    &:first-of-type {
      margin-left: 0;
    }

    &:last-of-type {
      margin-right: 0;
    }
  }

  @media (max-width: ${theme.breakpoints.sm}) {
    margin-right: ${theme.spacing[1]};
  }
`;

const LoadingWrapper = styled.div`
  > div {
    position: fixed;
  }
`;
